export interface User {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  gender: string;
  city: string;
  country: string;
  weight: number;
  height: number;
  email_verified_at: string;
  disclaimer_accepted_at: string;
  privacy_accepted_at: string;
  intake_completed_at: string;
}

// Value is initialized in: ~/plugins/auth.ts
export const useUser = () => {
  return useState<User | undefined | null>("user", () => undefined);
};

export const fetchCurrentUser = async () => {
  try {
    return await $larafetch<User>("/api/v1/user", {
      // redirectIfNotAuthenticated: false,
    });
  } catch (error: any) {
    if ([401, 419].includes(error?.response?.status)) return null;
    throw error;
  }
};
