import { $fetch } from "ofetch";
// import { parseCookies } from "h3";
import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { Capacitor } from "@capacitor/core";

const CSRF_COOKIE = "XSRF-TOKEN";
const CSRF_HEADER = "X-XSRF-TOKEN";

export const $larafetch = $fetch.create({
  credentials: "include",
  async onRequest({ options }) {
    const { apiBaseUrl, baseUrl } = useRuntimeConfig().public;
    const event = process.nitro ? useEvent() : null;

    // For some reason the next line sets an extra XSRF_TOKEN cookie on the subdomain, causing
    // token mismatch errors on login after a logout. That's why it's disabled for now.
    // let token = event
    //   ? parseCookies(event)[CSRF_COOKIE]
    //   : useCookie(CSRF_COOKIE).value;
    let token = null;

    if (!Capacitor.isNativePlatform()) {
      // on client initiate a csrf request and get it from the cookie set by laravel
      if (
        process.client &&
        ["post", "delete", "put", "patch"].includes(
          options?.method?.toLowerCase() ?? "",
        )
      ) {
        token = await initCsrf();
      }
    }

    let headers: any = {
      accept: "application/json",
      ...options?.headers,
      ...(token && { [CSRF_HEADER]: token }),
    };

    if (process.server && !Capacitor.isNativePlatform()) {
      const cookieString = event
        ? event.headers.get("cookie")
        : useRequestHeaders(["cookie"]).cookie;

      headers = {
        ...headers,
        ...(cookieString && { cookie: cookieString }),
        referer: baseUrl,
      };
    }

    if (Capacitor.isNativePlatform()) {
      // In a native app use token based authentication
      try {
        const token = await SecureStoragePlugin.get({ key: "authToken" });
        headers = {
          ...headers,
          Authorization: `Bearer ${token.value}`,
        };
      } catch (e) {
        console.log(e);
      }
    }

    options.headers = headers;
    options.baseURL = apiBaseUrl;
  },
  async onResponseError({ response }) {
    const status = response.status;
    if ([500].includes(status)) {
      console.error("[Laravel Error]", response.statusText, response._data);
    }
  },
});

async function initCsrf() {
  const { apiBaseUrl } = useRuntimeConfig().public;
  const existingToken = useCookie(CSRF_COOKIE).value;

  if (existingToken) return existingToken;

  await $fetch("/sanctum/csrf-cookie", {
    baseURL: apiBaseUrl,
    credentials: "include",
  });

  return useCookie(CSRF_COOKIE).value;
}
