import { default as _91_46_46_46slug_93S0PhT6Ifk3Meta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/[...slug].vue?macro=true";
import { default as chatTcwJemb6hfMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/chat.vue?macro=true";
import { default as forgot_45passwordMuwnzan8GzMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/forgot-password.vue?macro=true";
import { default as indexRo8DGuDIpgMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/index.vue?macro=true";
import { default as intakecG3k60XONsMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/intake.vue?macro=true";
import { default as logine823I6nyjXMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/login.vue?macro=true";
import { default as _91token_93ZeEnMJwsTmMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/password-reset/[token].vue?macro=true";
import { default as profilebdCgEA0eTCMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/profile.vue?macro=true";
import { default as register6yV9rGthUlMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/register.vue?macro=true";
import { default as verify_45email_45pendingThR3tyvBIzMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/verify-email-pending.vue?macro=true";
import { default as verify_45emailToCUw6D9zpMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/verify-email.vue?macro=true";
import { default as welcometsxrYw9ekJMeta } from "/home/ploi/app.deaileefstijlcoach.nl/pages/welcome.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93S0PhT6Ifk3Meta?.name ?? "slug",
    path: _91_46_46_46slug_93S0PhT6Ifk3Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93S0PhT6Ifk3Meta || {},
    alias: _91_46_46_46slug_93S0PhT6Ifk3Meta?.alias || [],
    redirect: _91_46_46_46slug_93S0PhT6Ifk3Meta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: chatTcwJemb6hfMeta?.name ?? "chat",
    path: chatTcwJemb6hfMeta?.path ?? "/chat",
    meta: chatTcwJemb6hfMeta || {},
    alias: chatTcwJemb6hfMeta?.alias || [],
    redirect: chatTcwJemb6hfMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordMuwnzan8GzMeta?.name ?? "forgot-password",
    path: forgot_45passwordMuwnzan8GzMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordMuwnzan8GzMeta || {},
    alias: forgot_45passwordMuwnzan8GzMeta?.alias || [],
    redirect: forgot_45passwordMuwnzan8GzMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexRo8DGuDIpgMeta?.name ?? "index",
    path: indexRo8DGuDIpgMeta?.path ?? "/",
    meta: indexRo8DGuDIpgMeta || {},
    alias: indexRo8DGuDIpgMeta?.alias || [],
    redirect: indexRo8DGuDIpgMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/index.vue").then(m => m.default || m)
  },
  {
    name: intakecG3k60XONsMeta?.name ?? "intake",
    path: intakecG3k60XONsMeta?.path ?? "/intake",
    meta: intakecG3k60XONsMeta || {},
    alias: intakecG3k60XONsMeta?.alias || [],
    redirect: intakecG3k60XONsMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/intake.vue").then(m => m.default || m)
  },
  {
    name: logine823I6nyjXMeta?.name ?? "login",
    path: logine823I6nyjXMeta?.path ?? "/login",
    meta: logine823I6nyjXMeta || {},
    alias: logine823I6nyjXMeta?.alias || [],
    redirect: logine823I6nyjXMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93ZeEnMJwsTmMeta?.name ?? "password-reset-token",
    path: _91token_93ZeEnMJwsTmMeta?.path ?? "/password-reset/:token()",
    meta: _91token_93ZeEnMJwsTmMeta || {},
    alias: _91token_93ZeEnMJwsTmMeta?.alias || [],
    redirect: _91token_93ZeEnMJwsTmMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: profilebdCgEA0eTCMeta?.name ?? "profile",
    path: profilebdCgEA0eTCMeta?.path ?? "/profile",
    meta: profilebdCgEA0eTCMeta || {},
    alias: profilebdCgEA0eTCMeta?.alias || [],
    redirect: profilebdCgEA0eTCMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: register6yV9rGthUlMeta?.name ?? "register",
    path: register6yV9rGthUlMeta?.path ?? "/register",
    meta: register6yV9rGthUlMeta || {},
    alias: register6yV9rGthUlMeta?.alias || [],
    redirect: register6yV9rGthUlMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/register.vue").then(m => m.default || m)
  },
  {
    name: verify_45email_45pendingThR3tyvBIzMeta?.name ?? "verify-email-pending",
    path: verify_45email_45pendingThR3tyvBIzMeta?.path ?? "/verify-email-pending",
    meta: verify_45email_45pendingThR3tyvBIzMeta || {},
    alias: verify_45email_45pendingThR3tyvBIzMeta?.alias || [],
    redirect: verify_45email_45pendingThR3tyvBIzMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/verify-email-pending.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailToCUw6D9zpMeta?.name ?? "verify-email",
    path: verify_45emailToCUw6D9zpMeta?.path ?? "/verify-email",
    meta: verify_45emailToCUw6D9zpMeta || {},
    alias: verify_45emailToCUw6D9zpMeta?.alias || [],
    redirect: verify_45emailToCUw6D9zpMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/verify-email.vue").then(m => m.default || m)
  },
  {
    name: welcometsxrYw9ekJMeta?.name ?? "welcome",
    path: welcometsxrYw9ekJMeta?.path ?? "/welcome",
    meta: welcometsxrYw9ekJMeta || {},
    alias: welcometsxrYw9ekJMeta?.alias || [],
    redirect: welcometsxrYw9ekJMeta?.redirect || undefined,
    component: () => import("/home/ploi/app.deaileefstijlcoach.nl/pages/welcome.vue").then(m => m.default || m)
  }
]