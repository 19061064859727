import validate from "/home/ploi/app.deaileefstijlcoach.nl/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/ploi/app.deaileefstijlcoach.nl/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/ploi/app.deaileefstijlcoach.nl/middleware/auth.ts"),
  guest: () => import("/home/ploi/app.deaileefstijlcoach.nl/middleware/guest.ts"),
  "intake-completed": () => import("/home/ploi/app.deaileefstijlcoach.nl/middleware/intake-completed.ts"),
  "intake-uncompleted": () => import("/home/ploi/app.deaileefstijlcoach.nl/middleware/intake-uncompleted.ts"),
  unverified: () => import("/home/ploi/app.deaileefstijlcoach.nl/middleware/unverified.ts"),
  verified: () => import("/home/ploi/app.deaileefstijlcoach.nl/middleware/verified.ts")
}